// noinspection LanguageDetectionInspection

import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Typography, Box, Button, CircularProgress } from '@material-ui/core';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { startModifyForm } from '../../redux/actions/form';

export const ThankYou = (props) => {
  const [submissionError, setSubmissionError] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const form = useSelector((state) => state.form[0]);
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const scrollToTop = () => {
    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 100);
  };

  const goToPrevState = () => {
    scrollToTop();
    dispatch(startModifyForm({ ...form, formState: 'safe-final' }));
  };

  const resetStates = () => {
    setSubmissionError(false);
    setIsSubmitted(false);
    setLoading(true);
  };

  const submitSurvey = useCallback(async () => {
    await resetStates();

    let data = {
      key: form.key,
      completed_by: form.isCarer,
      service_type: form.serviceType,
      age: form.patientAge,
      gender: form.patientGender,
      live_alone: form.patientLivingArrangements,
      postcode: form.patientPostcode,
      travel: form.patientTransportToHospital,
      travel_other: form.patientTransportOther,
      ethnicity: form.patientEthnicity,
      gender_identity: form.patientGenderIdentity,
      birth_gender_same: form.patientGenderAtBirth,
      sexual_orientation: form.patientSexualOrientation,
      safe_speed: form.safeSevenOne,
      safe_clarity: form.safeSevenTwo,
      safe_access: form.safeSevenThree,
      safe_kind: form.safeSevenFour,
      safe_trust: form.safeSevenFive,
      safe_listened: form.safeSevenSix,
      safe_care: form.safeSevenSeven,
      safe_good: form.patientGoodExperience,
      safe_bad: form.patientBadExperience,
    };
    console.log(data);

    // Submit form and display error if unsuccessful
    axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/survey/submit`, data)
      .then((res) => {
        console.log(res);
        setIsSubmitted(true);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setSubmissionError(true);
        setIsSubmitted(true);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    submitSurvey();
  }, [submitSurvey]);

  return (
    <React.Fragment>
      {loading && (
        <Box display={'flex'} justifyContent={'center'} mr={1}>
          <CircularProgress />
        </Box>
      )}
      {isSubmitted && !loading && (
        <>
          {submissionError && (
            <>
              <Box my={4}>
                <div className={'alert-box submission-error-box'}>
                  <Typography variant={'subtitle1'} align={'center'}>
                    {t('ERROR.MESSAGE')}
                  </Typography>
                </div>
                <Typography variant={'subtitle1'} align={'center'} className={'blue-font'}>
                  {t('ERROR.CONTENT')}
                  <a href="mailto:support@nhs.co.uk">{t('ERROR.LINK')}</a>
                </Typography>
              </Box>
              <Box display={'flex'} justifyContent={'center'} mr={1}>
                <Button
                  onClick={goToPrevState}
                  className={'action-bar-button submission-error-button'}
                  disabled={loading}
                >
                  Previous
                </Button>
                <Button
                  onClick={submitSurvey}
                  className={'action-bar-button submission-error-button'}
                  disabled={loading}
                >
                  Retry
                </Button>
              </Box>
            </>
          )}
          {!submissionError && (
            <>
              <Box display={'flex'} flexDirection={'center'} my={3}>
                <div className="heart-graphic"></div>
              </Box>
              <Typography variant={'h3'} align={'center'} className={'blue-font'}>
                {t('CONFIRMATION.THANK_YOU')}
              </Typography>
              <Box my={4}>
                <Typography variant={'subtitle1'} align={'center'} className={'blue-font'}>
                  {t('CONFIRMATION.CONTENT')}
                </Typography>
              </Box>
            </>
          )}
        </>
      )}
    </React.Fragment>
  );
};
