import React, { useState } from 'react';
import { Grid, Box, Typography, Button } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { startModifyForm } from '../../redux/actions/form';

export const ActionBar = (props) => {
  const [animateState] = useState('');
  const form = useSelector((state) => state.form[0]);

  const dispatch = useDispatch();

  const scrollToTop = () => {
    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 100);
  };

  const goToPrevState = () => {
    scrollToTop();
    dispatch(startModifyForm({ ...form, formState: props.prevState }));
  };

  const goToNextState = () => {
    scrollToTop();
    dispatch(startModifyForm({ ...form, formState: props.nextState }));
  };

  const handleValidation = () => {
    if (props.handleValidation) {
      props.handleValidation();
    }
  };

  return (
    <React.Fragment>
      <div className={'action-bar-space'}></div>
      <div className={'action-bar'}>
        <div className={'action-bar-grid-container'}>
          <Grid container>
            <Grid item xs={5} md={4}>
              <Box display={'flex'} justifyContent={'center'} mr={1}>
                <Button onClick={props.prevState && goToPrevState} className={'action-bar-button'}>
                  Previous
                </Button>
              </Box>
            </Grid>
            <Grid item xs={2} md={4}>
              <Box display={'flex'} justifyContent={'center'} my={3}>
                <Typography align={'center'}>
                  <strong>{props.copy}</strong>
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={5} md={4}>
              <Box display={'flex'} ml={1} justifyContent={'center'}>
                <Button
                  onClick={props.nextState ? goToNextState : handleValidation}
                  className={`action-bar-button ${
                    animateState === 'flashOn' ? 'btn-flash-on' : animateState === 'flashOff' ? 'btn-flash-off' : ''
                  } ${props.nextState ? '' : 'inactive'}`}
                >
                  Next
                </Button>
              </Box>
            </Grid>
          </Grid>
        </div>
      </div>
    </React.Fragment>
  );
};
