// background, decorator,
import React from 'react';
import { Grid, Typography, Box } from '@material-ui/core';

export const ParagraphBlock = (props) => {
  return (
    <div className={`paragraph-container ${props.background === 'white' ? 'white-bg' : ''}`}>
      <Grid container style={{ padding: '24px' }}>
        <Grid xs={1}>
          {props.decoration === 'icon' && (
            <p className="decoration-container">
              <span>
                <strong>i</strong>
              </span>
            </p>
          )}
          {props.decoration === 'line' && <div className="decoration-line">&nbsp;</div>}
        </Grid>
        <Grid xs={11}>
          <Box mx={2}>
            <Typography className={'primary-font'} variant={'subtitle1'}>
              {props.copy}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};
