export const modifyForm = (form) => ({
  type: 'MODIFY_FORM',
  form,
});

export const startModifyForm = (formData = {}) => {
  return (dispatch) => {
    const {
      name = formData.name,
      isCarer = formData.isCarer,
      serviceType = formData.serviceType,
      patientAge = formData.patientAge,
      patientGender = formData.patientGender,
      patientLivingArrangements = formData.patientLivingArrangements,
      patientPostcode = formData.patientPostcode,
      patientTransportToHospital = formData.patientTransportToHospital,
      patientTransportOther = formData.patientTransportOther,
      patientEthnicity = formData.patientEthnicity,
      patientGenderIdentity = formData.patientGenderIdentity,
      patientGenderAtBirth = formData.patientGenderAtBirth,
      patientSexualOrientation = formData.patientSexualOrientation,
      safeSevenOne = formData.safeSevenOne,
      safeSevenTwo = formData.safeSevenTwo,
      safeSevenThree = formData.safeSevenThree,
      safeSevenFour = formData.safeSevenFour,
      safeSevenFive = formData.safeSevenFive,
      safeSevenSix = formData.safeSevenSix,
      safeSevenSeven = formData.safeSevenSeven,
      patientGoodExperience = formData.patientGoodExperience,
      patientBadExperience = formData.patientBadExperience,
      formState = formData.formState,
      key = formData.key,
    } = formData;

    const form = {
      name,
      isCarer,
      serviceType,
      patientAge,
      patientGender,
      patientLivingArrangements,
      patientPostcode,
      patientTransportToHospital,
      patientTransportOther,
      patientEthnicity,
      patientGenderIdentity,
      patientGenderAtBirth,
      patientSexualOrientation,
      safeSevenOne,
      safeSevenTwo,
      safeSevenThree,
      safeSevenFour,
      safeSevenFive,
      safeSevenSix,
      safeSevenSeven,
      patientGoodExperience,
      patientBadExperience,
      formState,
      key,
    };
    if (form.formState !== 'start') {
      localStorage.setItem('safe7', JSON.stringify(form));
    }
    dispatch(
      modifyForm({
        ...form,
      })
    );
  };
};
