import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { ProgressIndicator } from '../indicators/ProgressIndicator';
import { useTranslation } from 'react-i18next';

export const ProgressBlock = (props) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div className={'progress-indicator-container'}>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Box mx={2}>
              <ProgressIndicator number={1} label={t('PROGRESS.ONE')} value={props.value1} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box mx={2}>
              <ProgressIndicator number={2} label={t('PROGRESS.TWO')} value={props.value2} />
            </Box>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default ProgressBlock;
