export const GenderAtBirth = [
  {
    name: 'Yes',
    value: 'yes',
  },
  {
    name: 'No',
    value: 'no',
  },
  {
    name: 'Prefer not to say',
    value: 'undisclosed',
  },
];

export default GenderAtBirth;
