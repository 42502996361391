import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { startModifyForm } from '../../../redux/actions/form';
import { TitleBlock } from '../../../components/blocks/TitleBlock';
import { InfoBlock } from '../../../components/blocks/InfoBlock';
import { ProgressBlock } from '../../../components/blocks/ProgressBlock';
import { Radiolist } from '../../../components/inputs/Radiolist';
import { ActionBar } from '../../../components/toolbars/ActionBar';
import { GenderIdentity } from '../../../enums/GenderIdentity';
import { GenderAtBirth } from '../../../enums/GenderAtBirth';
import { SexualOrientation } from '../../../enums/SexualOrientation';
import { useTranslation } from 'react-i18next';
import { FORM_TYPES, getStepData } from '../../../enums/Steps';

export const JourneyGenderForm = (props) => {
  const form = useSelector((state) => state.form[0]);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleGenderIdentitySelect = (genderIdentity) => {
    dispatch(startModifyForm({ ...form, patientGenderIdentity: genderIdentity }));
  };

  const handleGenderAtBirthSelect = (genderAtBirth) => {
    dispatch(startModifyForm({ ...form, patientGenderAtBirth: genderAtBirth }));
  };

  const handleSexualOrientationSelect = (sexualOrientation) => {
    dispatch(startModifyForm({ ...form, patientSexualOrientation: sexualOrientation }));
  };

  const [validation, setValidation] = useState(false);
  const validate = () => {
    setValidation(true);
  };

  const step = 'gender';
  const { nextStep, prevStep, currentStepIndicator, actionBarCopy } = getStepData(step, FORM_TYPES.journey);

  return (
    <React.Fragment>
      <ProgressBlock value1={currentStepIndicator} value2={0} />
      <div className="inner-form-container">
        <TitleBlock copy={t('FORM_GENDER.GENDER_IDENTITY_TITLE')} />
        <Radiolist
          options={GenderIdentity}
          isRows={true}
          selected={form.patientGenderIdentity}
          handleSelect={handleGenderIdentitySelect}
          validation={validation}
        />

        <TitleBlock copy={t('FORM_GENDER.GENDER_AT_BIRTH_TITLE')} />
        <Radiolist
          options={GenderAtBirth}
          isRows={true}
          selected={form.patientGenderAtBirth}
          handleSelect={handleGenderAtBirthSelect}
          validation={validation}
        />

        <TitleBlock copy={t('FORM_GENDER.GENDER_SEXUAL_ORIENTATION_TITLE')} />
        <Radiolist
          options={SexualOrientation}
          isRows={true}
          selected={form.patientSexualOrientation}
          handleSelect={handleSexualOrientationSelect}
          validation={validation}
        />
      </div>
      <ActionBar
        prevState={prevStep}
        handleValidation={validate}
        copy={actionBarCopy}
        nextState={
          form.patientGenderIdentity
            ? form.patientGenderAtBirth
              ? form.patientSexualOrientation
                ? nextStep
                : ''
              : ''
            : ''
        }
      />
    </React.Fragment>
  );
};
