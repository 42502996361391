import React from 'react';
import { Typography, Box } from '@material-ui/core';

export const TitleBlock = (props) => {
  return (
    <React.Fragment>
      <Box mb={1} mt={4} className={'title-container'}>
        <Typography align={'center'} className={'blue-font'} variant={'h5'}>
          <strong>{props.copy}</strong>
        </Typography>
      </Box>
    </React.Fragment>
  );
};
