import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { startModifyForm } from '../../../redux/actions/form';
import { Typography, Box } from '@material-ui/core';
import { ActionBar } from '../../../components/toolbars/ActionBar';
import { ProgressBlock } from '../../../components/blocks/ProgressBlock';
import { SafeSevenInput } from '../../../components/inputs/SafeSevenInput';
import { useTranslation } from 'react-i18next';
import { FORM_TYPES, getStepData } from '../../../enums/Steps';

export const Safe7One = (props) => {
  const form = useSelector((state) => state.form[0]);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleSafeSevenSelect = (value) => {
    dispatch(startModifyForm({ ...form, safeSevenOne: value }));
  };

  const [validation, setValidation] = useState(false);
  const validate = () => {
    setValidation(true);
  };

  const step = 'safe-1';
  const { nextStep, prevStep, currentStepIndicator, actionBarCopy } = getStepData(step, FORM_TYPES.safe7);

  return (
    <React.Fragment>
      <ProgressBlock value1={100} value2={currentStepIndicator} />
      <div className={'inner-form-container'}>
        <Box my={4}>
          <Typography variant={'h5'} align={'center'} className={'blue-font'}>
            <strong>{t('SAFE_SEVEN.Q_ONE')}</strong>
          </Typography>
        </Box>
        <Box my={4}>
          <div className="safe7-graphic safe7-graphic-1"></div>
        </Box>
        <SafeSevenInput selected={form.safeSevenOne} handleSelect={handleSafeSevenSelect} validation={validation} />
      </div>
      <ActionBar
        prevState={prevStep}
        handleValidation={validate}
        copy={actionBarCopy}
        nextState={form.safeSevenOne ? nextStep : ''}
      />
    </React.Fragment>
  );
};
