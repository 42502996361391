export const FORM_TYPES = {
  journey: 'journey',
  safe7: 'safe7',
};

const STEPS = {
  start: 'start',
  [FORM_TYPES.journey]: {
    steps: ['info', 'age', 'postcode', 'travel', 'ethnicity', 'gender'],
    indicator_values: [1, 15, 40, 60, 80, 95],
  },
  [FORM_TYPES.safe7]: {
    steps: ['safe-intro', 'safe-1', 'safe-2', 'safe-3', 'safe-4', 'safe-5', 'safe-6', 'safe-7', 'safe-final'],
    indicator_values: [3, 12.5, 25, 37.5, 50, 62.5, 75, 87.5, 95],
  },
  thankyou: 'thank-you',
};

export const getStepData = (formName, formType) => {
  const index = STEPS[formType].steps.findIndex((name) => name === formName);
  const lastIndex = STEPS[formType].steps.length - 1;
  const isFirstStep = index === 0;
  const isLastIndex = index === lastIndex;

  const getNextStep = () => {
    switch (formType) {
      case FORM_TYPES.journey:
        if (isLastIndex) return STEPS[FORM_TYPES.safe7].steps[0];
        else return STEPS[formType].steps[index + 1];
      case FORM_TYPES.safe7:
        if (isLastIndex) return STEPS.thankyou;
        else return STEPS[formType].steps[index + 1];
      default:
        break;
    }
  };

  const getPrevStep = () => {
    switch (formType) {
      case FORM_TYPES.journey:
        if (isFirstStep) return STEPS.start;
        else return STEPS[formType].steps[index - 1];
      case FORM_TYPES.safe7:
        if (isFirstStep) return STEPS[FORM_TYPES.journey].steps.at(-1);
        else return STEPS[formType].steps[index - 1];
      default:
        break;
    }
  };

  return {
    prevStep: getPrevStep(),
    nextStep: getNextStep(),
    currentStep: STEPS[formType].steps[index],
    currentStepIndicator: STEPS[formType].indicator_values[index],
    actionBarCopy: `${index} of ${formType === FORM_TYPES.journey ? lastIndex : lastIndex - 1}`,
  };
};
