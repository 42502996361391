import React from 'react';
import { useSelector } from 'react-redux';
import { ActionBar } from '../../../components/toolbars/ActionBar';
import { ProgressBlock } from '../../../components/blocks/ProgressBlock';
import { Typography, Box } from '@material-ui/core';
import { SafeSevenInput } from '../../../components/inputs/SafeSevenInput';
import { useTranslation } from 'react-i18next';
import { FORM_TYPES, getStepData } from '../../../enums/Steps';

export const Safe7Intro = (props) => {
  const { t } = useTranslation();
  const handleSafeSevenSelect = () => {};

  const step = 'safe-intro';
  const { nextStep, prevStep, currentStepIndicator } = getStepData(step, FORM_TYPES.safe7);

  console.log(prevStep);
  return (
    <React.Fragment>
      <ProgressBlock value1={100} value2={currentStepIndicator} />
      <div className="inner-form-container">
        <Box my={4}>
          <Typography variant={'h3'} align={'center'} className={'blue-font'}>
            <strong>{t('SAFE_SEVEN.TITLE')}</strong>
          </Typography>
        </Box>
        <Typography variant={'h6'} className={'blue-font'}>
          {t('SAFE_SEVEN.PARAGRAPH')}
        </Typography>
        <Box my={4}>
          <SafeSevenInput demo selected={8} handleSelect={handleSafeSevenSelect} />
        </Box>
      </div>
      <ActionBar prevState={prevStep} copy={''} nextState={nextStep} />
    </React.Fragment>
  );
};
