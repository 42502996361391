import React from 'react';

export const EN = {
  PLEASE_SELECT_OPTION: 'Please select an option',
  DATE_SELECTED: 'Date Selected',
  NOT_KNOWN: "I don't know",
  BEST: 'Best',
  WORST: 'Worst',
  MORE_INFO: {
    TITLE: 'More Info',
  },
  PROGRESS: {
    ONE: 'Your Journey with Us',
    TWO: 'The Safe-7 Questions',
  },
  FORM_START: {
    TITLE: 'We value your opinion',
    PARA_ONE:
      'This anonymous survey will help us to understand our patients and their families/carers experience of care. It has been designed by our patients for our patients. It will provide real-time feedback on the service we provide and ensure we are constantly trying to find ways to improve',
    PARA_TWO:
      'Thank you for taking the time to complete this anonymous survey, your comments will help us try to make every patient feel safe in our care.',
    PARA_THREE: 'The Lung Cancer & Thoracic Surgery Team at Wythenshawe Hospital.',
    BUTTON: 'Start Survey',
    OPTION_ONE: 'I am the patient',
    OPTION_TWO: 'I am the family member / carer of the patient',
  },
  FORM_INFO: {
    TITLE: 'You and Your Journey with Us',
    SUBHEADING:
      'When we examine the experience of care for our patients, it is important to know if all patients receive the same experience of care regardless of age, gender, ethnicity, financial status, sexual orientation etc.',
    BULLET_ONE:
      'We therefore want to ask you a few questions about yourself so we can understand the experience of care of our patients across all these areas that make every patient unique',
    BULLET_TWO: 'These questions are anonymous and no data is stored that could identify you individually.',
    CONCLUSION:
      'Thank you for taking the time to complete this survey and tell us a little more about who you are and your journey with us.',
  },
  FORM_DATES: {
    INFO_BOX:
      '“Patients are referred to our team because there has been a cancer raised about the possibility of cancer, and it is our job to find out whether that is true or not.',
    DATE_RAISED_TITLE: 'What was the first date that the possibility of cancer was raised?',
    DATE_RAISED_MORE_INFO_CONTENT:
      "Please could you tell us, if you can, the first date that the possibility was raised. This might be the date your GP explained you were being referred to our team for tests to look for cancer, or it might have been the date another team at the hospital told you that you were being referred to our team for tests. Don’t worry if you can’t provide this date, just tick the ‘I don't know’ button.",
    DATE_EXPLAINED_TITLE: 'What date did we explain all of your test results and final diagnosis?',
    DATE_EXPLAINED_MORE_INFO_CONTENT:
      'Please provide the date when we explained all the results of your tests and a final diagnosis. This will often be a clinic appointment with one of our team and is often after a large team meeting called an ‘MDT’ where we discuss all tests results and plans as a whole team.',
  },
  DATE_INPUT: {
    DATE_SELECTED: 'Date selected',
    DO_NOT_KNOW: "I Don't Know",
  },
  FORM_AGE: {
    AGE_TITLE: 'How old are you at the time of completing this survey?',
    GENDER_TITLE: 'Sex',
    GENDER_MORE_INFO: 'Lorem Ipsum',
    GENDER_OPTION_ONE: 'Male',
    GENDER_OPTION_TWO: 'Female',
  },
  FORM_POSTCODE: {
    POSTCODE_INFO:
      'This information helps us to understand the communities our patients come from and to make sure the experience of care is the same for all those communities. Your postcode will provide us with an area you are from and the postcode is then destroyed. This information is not stored in this system and the answers are completely anonymous.',
    LIVE_ALONE_TITLE: 'Do you live alone?',
    LIVE_ALONE_MORE_INFO: 'Lorem ipsum',
    LIVE_ALONE_OPTION_ONE: 'Yes',
    LIVE_ALONE_OPTION_TWO: 'No',
    LIVE_ALONE_OPTION_THREE: 'Prefer Not To Say',
    POSTCODE_TITLE: 'What is your postcode?',
    POSTCODE_MORE_INFO: 'Lorem ipsum',
  },
  FORM_TRANSPORT: {
    TRANSPORT_TITLE: 'What is the most common form of transport you use to travel to hospital appointments and tests?',
    TRANSPORT_OPTION_ONE: 'Drive my own vehicle',
    TRANSPORT_OPTION_TWO: 'Public Transport (Bus, Train, Etc)',
    TRANSPORT_OPTION_THREE: 'Taxi',
    TRANSPORT_OPTION_FOUR: 'Family / Friend Drives Me',
    TRANSPORT_OPTION_FIVE: 'Hospital Transport Service',
    TRANSPORT_OPTION_SIX: 'Others',
    TRANSPORT_OPTION_OTHER: 'Please specify',
    HOSPITAL_COUNT_TITLE: 'How many different hospitals did you have to travel to complete all your tests?',
    HOSPITAL_COUNT_MORE_INFO:
      'Different tests to look for cancer are done at different hospitals, and we want to understand the impact of travelling to different hospitals has on the experience of care of our patients',
    HOSPITAL_OPTION_NOT_SURE: "I'm Not Sure",
  },
  FORM_TESTS: {
    TEST_TITLE: 'Please tick all the different tests you have had under our care',
    MORE_INFO:
      'Different tests to look for cancer are done at different hospitals, and we want to understand the impact of travelling to different hospitals has on the experience of care of our patients',
    TEST_OPTION_ONE: 'CT scan of the lungs',
    TEST_OPTION_TWO: 'PET scan',
    TEST_OPTION_THREE: 'Brain scan',
    TEST_OPTION_FOUR: 'Bronchoscopy',
    TEST_OPTION_FIVE: 'EBUS',
    TEST_OPTION_SIX: 'Lung biopsy',
    TEST_OPTION_SEVEN: 'CT scan or MR scan of a different part of the body',
    TEST_OPTION_EIGHT: 'A different kind of biopsy',
    TEST_OPTION_NINE: 'Breathing tests',
    TEST_OPTION_TEN: 'Heart scan',
    TEST_OPTION_ELEVEN: 'Exercise test',
    TEST_OPTION_TWELVE: "I'm not sure",
    TEST_OPTION_THIRTEEN: 'Others',
    TEST_OPTION_OTHER: 'Please specify',
  },
  FORM_ETHNICITY: {
    ETHNICITY_QUESTION: 'Which of these best describes your ethnic group?',
    ETHNICITY_OPTION_AFRICAN: 'African',
    ETHNICITY_OPTION_ARAB: 'Arab',
    ETHNICITY_OPTION_ASIAN_BANGLADESHI: 'Asian - Bangladeshi',
    ETHNICITY_OPTION_ASIAN_CHINESE: 'Asian - Chinese',
    ETHNICITY_OPTION_ASIAN_INDIAN: 'Asian - Indian',
    ETHNICITY_OPTION_ASIAN_OTHER: 'Asian - Any other Asian background',
    ETHNICITY_OPTION_ASIAN_PAKISTANI: 'Asian - Pakistani',
    ETHNICITY_OPTION_BLACK_OTHER: 'Any other Black / African / Caribbean background',
    ETHNICITY_OPTION_CARIBBEAN: 'Caribbean',
    ETHNICITY_OPTION_MIXED_MULTIPLE_ETHNIC: 'Any other Mixed/Multiple ethnic background',
    ETHNICITY_OPTION_OTHER: 'Any other ethnic group',
    ETHNICITY_OPTION_WHITE: 'White',
    ETHNICITY_OPTION_WHITE_ASIAN: 'White and Asian',
    ETHNICITY_OPTION_WHITE_BLACK_AFRICAN: 'White and Black African',
    ETHNICITY_OPTION_WHITE_BLACK_CARIBBEAN: 'White and Black Caribbean',
  },
  FORM_GENDER: {
    GENDER_IDENTITY_TITLE: 'Which of the following describes how you think of yourself?',
    GENDER_IDENTITY_MORE_INFO: 'Lorem Ipsum',
    GENDER_IDENTITY_ANOTHER_WAY: 'In Another Way',
    GENDER_IDENTITY_MAN: 'Man (including trans men)',
    GENDER_IDENTITY_NON_BINARY: 'Non-binary',
    GENDER_IDENTITY_NOT_SAY: 'Prefer not to say',
    GENDER_IDENTITY_WOMAN: 'Woman (including trans women)',
    GENDER_AT_BIRTH_TITLE: 'Is your gender different to the sex you were observed to be at birth?',
    GENDER_AT_BIRTH_MORE_INFO: 'Lorem Ipsum',
    GENDER_BIRTH_NO: 'No',
    GENDER_BIRTH_NOT_SAY: 'Prefer not to say',
    GENDER_BIRTH_YES: 'Yes',
    GENDER_SEXUAL_ORIENTATION_TITLE: 'Which of these best describes your sexual orientation?',
    GENDER_SEXUAL_ORIENTATION_MORE_INFO: 'Lorem Ipsum',
    GENDER_SEXUAL_ORIENTATION_OPTION_ONE: 'Straight/Heterosexual',
    GENDER_SEXUAL_ORIENTATION_OPTION_TWO: 'Homosexual, lesbian, gay',
    GENDER_SEXUAL_ORIENTATION_OPTION_THREE: 'Bisexual',
    GENDER_SEXUAL_ORIENTATION_OPTION_FOUR: 'Prefer not to say',
  },
  SAFE_SEVEN: {
    TITLE: 'The Safe 7',
    PARAGRAPH:
      "You will now be asked to rate your experience of care in the 'pillars of experience of care' defined by our patient representatives that have designed this survey. Please rate your experience of care in each of these areas. At the end you will be able to write specific feedback (good or bad) and please do provide as much feedback as possible. Thank you for taking the time to complete this survey.",
    Q_ONE: '1. How satisfied were you with the speed of the service?',
    Q_TWO: '2. How clear was the information you were provided with by our team?',
    Q_THREE: '3. How easily were you able to access the team if you needed them?',
    Q_FOUR: '4. How kind were the team that cared for you?',
    Q_FIVE: '5. How would you rate your level of trust and confidence in the team that cared for you?',
    Q_SIX: '6. How would you rate how well we listened to you and your worries/concerns?',
    Q_SEVEN: 'Overall how safe did you feel under our care?',
  },
  FORM_EXPERIENCE: {
    TITLE: 'Your Experience',
    GOOD: 'Please let us know if there was anything that was especially good about your experience',
    BAD: 'Please let us know if there was anything that was especially bad about your experience',
  },
  CONFIRMATION: {
    THANK_YOU: 'Thank you for completing the survey!',
    CONTENT:
      'We value your opinion and really appreciated that you took your time to answer those questions. We would also like to make clear that we will not store or share your data with any third-party company.',
  },
  ERROR: {
    MESSAGE: 'Sorry, there was an error when submitting the survey!',
    CONTENT: 'Please press the button to retry. If the issue persists, please contact us at ',
    LINK: 'support@nhs.co.uk',
  },
};

export default EN;
