import React from 'react';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import { AlertBlock } from '../blocks/AlertBlock';
import { Box } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

export const ToggleOption = React.memo((props) => {
  const handleToggleChange = (value) => {
    if (value.value === undefined) {
      value = value.offsetParent.value;
    } else {
      value = value.value;
    }
    if (props.isMultiple) {
      if (props.selected == null) {
        props.handleSelect([value]);
      } else {
        if ([...new Set([...props.selected]), value].length === [...new Set([...props.selected, value])].length) {
          props.handleSelect([...new Set([...props.selected]), value]);
        } else {
          props.handleSelect(props.selected.filter((el) => el !== value));
        }
      }
    } else {
      props.handleSelect(value);
    }
  };

  return (
    <React.Fragment>
      <div className={'form-input-container'}>
        <AlertBlock active={!props.selected} validation={props.validation} />
        {props.selected && (
          <div className={`complete-indicator-container ${props.validationIndicatorMoreRight ? 'more-right' : ''}`}>
            <CheckCircleIcon />
          </div>
        )}
        <ToggleButtonGroup
          exclusive={props.isMultiple ? false : true}
          value={props.selected}
          aria-label="text alignment"
          className={`toggle-option-container ${props.options.length === 2 ? 'toggle-group-two-column' : ''}`}
        >
          {props.options.map((option, index) => {
            return (
              <ToggleButton
                className={props.twoButtonRow ? 'wrap-two-columns' : ''}
                value={option.value}
                onClick={(e) => handleToggleChange(e.target)}
              >
                <Box className={`toggle-box ${option.icon ? 'toggle-box-icon' : ''}`}>
                  <div className={`${option.icon ? 'toggle-group-icon' : ''} ${option?.icon}`}></div>
                  <span className={'toggle-option-label'}>{option.name}</span>
                </Box>
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>
      </div>
    </React.Fragment>
  );
});
