export const LiveAloneOptions = [
  {
    value: 'yes',
    name: 'Yes',
  },
  {
    value: 'no',
    name: 'No',
  },
  {
    value: 'undisclosed',
    name: 'Prefer not to say',
  },
];

export default LiveAloneOptions;
