import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { EN } from './assets/lang/en.json.js';
import { DE } from './assets/lang/de.json.js';

// import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: true,

    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: EN,
      },
      de: {
        translation: DE,
      },
    },
  });

export default i18n;
