import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { startModifyForm } from './../../redux/actions/form';

import { Grid, Box, Typography } from '@material-ui/core';
import { StartForm } from '../forms/StartForm';
import { JourneyInfoForm } from '../forms/journey/JourneyInfoForm';
import { JourneyAgeForm } from '../forms/journey/JourneyAgeForm';
import { JourneyPostcodeForm } from '../forms/journey/JourneyPostcodeForm';
import { JourneyTravelForm } from '../forms/journey/JourneyTravelForm';
import { JourneyEthnicityForm } from '../forms/journey/JourneyEthnicityForm';
import { JourneyGenderForm } from '../forms/journey/JourneyGenderForm';

import { Safe7Intro } from '../forms/safe7/Safe7-Intro';
import { Safe7One } from '../forms/safe7/Safe7-1';
import { Safe7Two } from '../forms/safe7/Safe7-2';
import { Safe7Three } from '../forms/safe7/Safe7-3';
import { Safe7Four } from '../forms/safe7/Safe7-4';
import { Safe7Five } from '../forms/safe7/Safe7-5';
import { Safe7Six } from '../forms/safe7/Safe7-6';
import { Safe7Seven } from '../forms/safe7/Safe7-7';

import { Safe7Final } from '../forms/safe7/Safe7-Final';
import { ThankYou } from '../forms/ThankYou';
import { Unauthorized } from '../utility/Unauthorized';

import { Switch, Route, useHistory } from 'react-router-dom';

export const JourneyWrapper = (props) => {
  const [animateState, setAnimateState] = useState('');
  const [formState, setFormState] = useState('');
  const [hasEntered, setHasEntered] = useState(false);
  const history = useHistory();
  const formStateRedux = useSelector((state) => state.form[0].formState);
  const form = useSelector((state) => state.form[0]);
  const dispatch = useDispatch();
  let urlArr = window.location.href.split('/');
  const key = urlArr[4];

  const handleKey = (k) => {
    dispatch(startModifyForm({ ...form, key: k }));
  };

  useEffect(() => {
    let existingData = JSON.parse(localStorage.getItem('safe7'));
    setAnimateState('fadeOut');
    setTimeout(function () {
      if (key !== undefined) {
        let data = { key: key };
        axios
          .post(`${process.env.REACT_APP_API_ENDPOINT}/survey/verify-token`, data)
          .then((res) => {
            if (res.status === 200) {
              handleKey(key);
              if (hasEntered) {
                dispatch(startModifyForm({ ...form, ...existingData }));
                history.push('/survey/' + key + '/' + existingData.formState);
                setHasEntered(true);
              } else {
                history.push('/survey/' + key + '/' + formStateRedux);
              }
              setAnimateState('fadeIn');
              setFormState(formStateRedux);
            }
          })
          .catch((err) => {
            if (err?.response?.data) {
              history.push('/unauthorized');
              setAnimateState('fadeIn');
            }
          });
      } else {
        history.push('/unauthorized');
        setAnimateState('fadeIn');
      }
    }, 100);
  }, [formStateRedux]);

  return (
    <div className={'wrapper-container'}>
      <Grid container className={'inner-wrapper-container'}>
        <div
          className={`form-container ${
            animateState === 'fadeOut' ? 'form-fade-out' : animateState === 'fadeIn' ? 'form-fade-in' : ''
          }`}
        >
          <Switch>
            <Route path={'/unauthorized'}>
              <Unauthorized />
            </Route>
            <Route path={'/survey/' + key + '/start'}>
              <StartForm />
            </Route>
            <Route path={'/survey/' + key + '/info'}>
              <JourneyInfoForm />
            </Route>
            <Route path={'/survey/' + key + '/age'}>
              <JourneyAgeForm />
            </Route>
            <Route path={'/survey/' + key + '/postcode'}>
              <JourneyPostcodeForm />
            </Route>
            <Route path={'/survey/' + key + '/travel'}>
              <JourneyTravelForm />
            </Route>
            <Route path={'/survey/' + key + '/ethnicity'}>
              <JourneyEthnicityForm />
            </Route>
            <Route path={'/survey/' + key + '/gender'}>
              <JourneyGenderForm />
            </Route>
            <Route path={'/survey/' + key + '/safe-intro'}>
              <Safe7Intro />
            </Route>
            <Route path={'/survey/' + key + '/safe-1'}>
              <Safe7One />
            </Route>
            <Route path={'/survey/' + key + '/safe-2'}>
              <Safe7Two />
            </Route>
            <Route path={'/survey/' + key + '/safe-3'}>
              <Safe7Three />
            </Route>
            <Route path={'/survey/' + key + '/safe-4'}>
              <Safe7Four />
            </Route>
            <Route path={'/survey/' + key + '/safe-5'}>
              <Safe7Five />
            </Route>
            <Route path={'/survey/' + key + '/safe-6'}>
              <Safe7Six />
            </Route>
            <Route path={'/survey/' + key + '/safe-7'}>
              <Safe7Seven />
            </Route>
            <Route path={'/survey/' + key + '/safe-final'}>
              <Safe7Final />
            </Route>
            <Route path={'/survey/' + key + '/thank-you'}>
              <ThankYou />
            </Route>
          </Switch>
        </div>
      </Grid>
      <div className={'bg-plants-graphic'}></div>
    </div>
  );
};

export default JourneyWrapper;
