export const GenderIdentity = [
  {
    name: 'Man (including trans men)',
    value: 'man',
  },
  {
    name: 'Woman (including trans women)',
    value: 'woman',
  },
  {
    name: 'Non-binary',
    value: 'non_binary',
  },
  {
    name: 'In Another Way',
    value: 'other',
  },
  {
    name: 'Prefer not to say',
    value: 'undisclosed',
  },
];

export default GenderIdentity;
