export const Transport = [
  {
    name: 'Drive my own vehicle',
    value: 'own_vehicle',
    icon: 'car-icon',
  },
  {
    name: 'Public transport (bus, train, etc)',
    value: 'public',
    icon: 'ticket-icon',
  },
  {
    name: 'Taxi',
    value: 'taxi',
    icon: 'taxi-icon',
  },
  {
    name: 'Family / friend drives me',
    value: 'relative',
    icon: 'friend-icon',
  },
  {
    name: 'Hospital transport service',
    value: 'hospital_transport',
    icon: 'hospital-icon',
  },
  {
    name: 'Others',
    value: 'others',
    icon: 'qmark-icon',
  },
];

export default Transport;
