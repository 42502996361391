import React from 'react';
import { Typography, Box, Grid } from '@material-ui/core';
import { ActionBar } from '../../../components/toolbars/ActionBar';
import { ProgressBlock } from '../../../components/blocks/ProgressBlock';
import { useTranslation } from 'react-i18next';
import { FORM_TYPES, getStepData } from '../../../enums/Steps';

export const JourneyInfoForm = (props) => {
  const { t } = useTranslation();

  const step = 'info';
  const { nextStep, currentStepIndicator } = getStepData(step, FORM_TYPES.journey);
  return (
    <React.Fragment>
      <Grid container>
        <ProgressBlock value1={currentStepIndicator} value2={0} />
      </Grid>
      <Grid container className="inner-form-container">
        <Box mt={8} mb={2}>
          <Typography className={'blue-font'} align={'center'} variant={'h5'}>
            <strong>{t('FORM_INFO.TITLE')}</strong>
          </Typography>
        </Box>
        <Typography className={'blue-font'} variant={'subtitle1'}>
          <strong>{t('FORM_INFO.SUBHEADING')}</strong>
        </Typography>
        <ul style={{ 'padding-inline-start': '0px' }}>
          <Box my={2}>
            <li>
              <Typography className="blue-font" variant={'subtitle1'}>
                {t('FORM_INFO.BULLET_ONE')}
              </Typography>
            </li>
          </Box>
          <Box my={2}>
            <li>
              <Typography className="blue-font" variant={'subtitle1'}>
                {t('FORM_INFO.BULLET_TWO')}
              </Typography>
            </li>
          </Box>
        </ul>
        <Typography className="blue-font" variant={'subtitle1'}>
          {t('FORM_INFO.CONCLUSION')}
        </Typography>
      </Grid>
      <ActionBar prevState={'start'} copy={''} nextState={nextStep} />
    </React.Fragment>
  );
};
