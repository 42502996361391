export const SexualOrientation = [
  {
    name: 'Straight/Heterosexual',
    value: 'heterosexual',
  },
  {
    name: 'Homosexual, lesbian, gay',
    value: 'homosexual',
  },
  {
    name: 'Bisexual',
    value: 'bisexual',
  },
  {
    name: 'Prefer not to say',
    value: 'undisclosed',
  },
];

export default SexualOrientation;
