import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import thunk from 'redux-thunk';
import { formReducer } from './../reducers/form';
// import { persistStore, persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';

// Store creation
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const configStore = () => {
  const store = createStore(
    combineReducers({
      form: formReducer,
    }),
    composeEnhancers(applyMiddleware(thunk))
  );
  return store;
};

export default configStore;
