import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { startModifyForm } from '../../../redux/actions/form';
import { TitleBlock } from '../../../components/blocks/TitleBlock';
import { ProgressBlock } from '../../../components/blocks/ProgressBlock';
import { Radiolist } from '../../../components/inputs/Radiolist';
import { ActionBar } from '../../../components/toolbars/ActionBar';
import { useTranslation } from 'react-i18next';
import { FORM_TYPES, getStepData } from '../../../enums/Steps';

export const JourneyEthnicityForm = (props) => {
  const form = useSelector((state) => state.form[0]);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const step = 'ethnicity';
  const { nextStep, prevStep, currentStepIndicator, actionBarCopy } = getStepData(step, FORM_TYPES.journey);

  const Ethnicities = [
    {
      name: t('FORM_ETHNICITY.ETHNICITY_OPTION_WHITE'),
      value: 'white',
    },
    {
      name: t('FORM_ETHNICITY.ETHNICITY_OPTION_WHITE_BLACK_CARIBBEAN'),
      value: 'white_black_caribbean',
    },
    {
      name: t('FORM_ETHNICITY.ETHNICITY_OPTION_WHITE_BLACK_AFRICAN'),
      value: 'white_black_african',
    },
    {
      name: t('FORM_ETHNICITY.ETHNICITY_OPTION_WHITE_ASIAN'),
      value: 'white_asian',
    },
    {
      name: t('FORM_ETHNICITY.ETHNICITY_OPTION_MIXED_MULTIPLE_ETHNIC'),
      value: 'mixed',
    },
    {
      name: t('FORM_ETHNICITY.ETHNICITY_OPTION_ASIAN_INDIAN'),
      value: 'asian_indian',
    },
    {
      name: t('FORM_ETHNICITY.ETHNICITY_OPTION_ASIAN_PAKISTANI'),
      value: 'asian_pakistani',
    },
    {
      name: t('FORM_ETHNICITY.ETHNICITY_OPTION_ASIAN_BANGLADESHI'),
      value: 'asian_bangladeshi',
    },
    {
      name: t('FORM_ETHNICITY.ETHNICITY_OPTION_ASIAN_CHINESE'),
      value: 'asian_chinese',
    },
    {
      name: t('FORM_ETHNICITY.ETHNICITY_OPTION_ASIAN_OTHER'),
      value: 'asian_other',
    },
    {
      name: t('FORM_ETHNICITY.ETHNICITY_OPTION_AFRICAN'),
      value: 'african',
    },
    {
      name: t('FORM_ETHNICITY.ETHNICITY_OPTION_CARIBBEAN'),
      value: 'caribbean',
    },
    {
      name: t('FORM_ETHNICITY.ETHNICITY_OPTION_BLACK_OTHER'),
      value: 'black_african_caribbean_other',
    },
    {
      name: t('FORM_ETHNICITY.ETHNICITY_OPTION_ARAB'),
      value: 'arab',
    },
    {
      name: t('FORM_ETHNICITY.ETHNICITY_OPTION_OTHER'),
      value: 'other',
    },
  ];

  const handleEthnicitySelect = (ethnicity) => {
    dispatch(startModifyForm({ ...form, patientEthnicity: ethnicity }));
  };

  const [validation, setValidation] = useState(false);
  const validate = () => {
    setValidation(true);
  };

  return (
    <React.Fragment>
      <ProgressBlock value1={currentStepIndicator} value2={0} />
      <div className="inner-form-container">
        <TitleBlock copy={t('FORM_ETHNICITY.ETHNICITY_QUESTION')} />
        <br />
        <Radiolist
          options={Ethnicities}
          selected={form.patientEthnicity}
          isRows={true}
          handleSelect={handleEthnicitySelect}
          validation={validation}
        />
      </div>
      <ActionBar
        prevState={prevStep}
        handleValidation={validate}
        copy={actionBarCopy}
        nextState={form.patientEthnicity ? nextStep : ''}
      />
    </React.Fragment>
  );
};
