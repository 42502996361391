import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { startModifyForm } from '../../../redux/actions/form';
import { TitleBlock } from '../../../components/blocks/TitleBlock';
import { InfoBlock } from '../../../components/blocks/InfoBlock';
import { AlertBlock } from '../../../components/blocks/AlertBlock';
import { ProgressBlock } from '../../../components/blocks/ProgressBlock';
import { ParagraphBlock } from '../../../components/blocks/ParagraphBlock';
import { ToggleOption } from '../../../components/inputs/ToggleOption';
import { TextInput } from '../../../components/inputs/TextInput';
import { ActionBar } from '../../../components/toolbars/ActionBar';
import { LiveAloneOptions } from '../../../enums/LiveAloneOptions';
import { CircularProgress, Box, Typography } from '@material-ui/core';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { FORM_TYPES, getStepData } from '../../../enums/Steps';

export const JourneyPostcodeForm = (props) => {
  const form = useSelector((state) => state.form[0]);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [postcodeLoading, setPostcodeLoading] = useState(false);
  const [postcodeError, setPostcodeError] = useState('');
  const handleLivingArrangementsSelect = (livingArrangements) => {
    dispatch(startModifyForm({ ...form, patientLivingArrangements: livingArrangements }));
  };

  const step = 'postcode';
  const { currentStep, nextStep, prevStep, currentStepIndicator, actionBarCopy } = getStepData(
    step,
    FORM_TYPES.journey
  );

  const handlePostcodeInput = (postcode) => {
    // console.log(postcode);
    // console.log(postcode.target.value);
    setPostcodeLoading(true);
    // setTimeout(function () {
    //   setPostcodeLoading(false)
    //   dispatch(startModifyForm({...form, patientPostcode: postcode.target.value}))
    // }, 5000);

    let data = {
      key: form.key,
      postcode: postcode.target.value,
    };
    if (data.postcode.length >= 6) {
      axios
        .post(`${process.env.REACT_APP_API_ENDPOINT}/survey/convert-postcode`, data)
        .then((res) => {
          console.log(res);
          setPostcodeLoading(false);
          setPostcodeError('');
          dispatch(startModifyForm({ ...form, patientPostcode: res.data.lsoa11cd }));
        })
        .catch((err) => {
          setPostcodeLoading(false);
          console.log(err);
          console.log(err.response);
          console.log(err.response.error_message);
          if (err.response.error_message) {
            setPostcodeError(err?.response?.error_message);
          } else {
            setPostcodeError('Oops, something went wrong!');
          }
        });
    }
  };

  const [validation, setValidation] = useState(false);
  const validate = () => {
    setValidation(true);
  };

  return (
    <React.Fragment>
      <ProgressBlock value1={currentStepIndicator} value2={0} />
      <div className={'inner-form-container'}>
        <br />
        <ParagraphBlock decoration={'icon'} background={'white'} copy={t('FORM_POSTCODE.POSTCODE_INFO')} />
        <TitleBlock copy={t('FORM_POSTCODE.LIVE_ALONE_TITLE')} />
        <ToggleOption
          options={LiveAloneOptions}
          handleSelect={handleLivingArrangementsSelect}
          selected={form.patientLivingArrangements}
          validation={validation}
        />
        <AlertBlock active={postcodeError} copy={postcodeError} />
        <TitleBlock copy={t('FORM_POSTCODE.POSTCODE_TITLE')} />
        <TextInput handleKeyUp={handlePostcodeInput} defaultValue={form.patientPostcode} />
        {postcodeLoading && (
          <Box display={'flex'} justifyContent={'center'} mt={1}>
            <CircularProgress />
          </Box>
        )}
        {postcodeError && (
          <Box my={1}>
            <Typography variant={'subtitle1'} align={'center'} className={'red-font'}>
              {postcodeError}
            </Typography>
          </Box>
        )}
      </div>
      <ActionBar
        prevState={prevStep}
        handleValidation={validate}
        copy={actionBarCopy}
        nextState={form.patientLivingArrangements ? (form.patientPostcode ? nextStep : '') : ''}
      />
    </React.Fragment>
  );
};
