import React from 'react';
import { Select, MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { IconFlagUK, IconFlagDE } from 'material-ui-flags';

const lngs = {
  en: { nativeName: 'English' },
  de: { nativeName: 'Deutsch' },
};

export const LanguageInput = (props) => {
  const { i18n } = useTranslation();
  const handleChange = (e) => {
    i18n.changeLanguage(e.target.value);
  };

  return (
    <React.Fragment>
      <Select
        variant={'outlined'}
        size={'small'}
        defaultValue={'en'}
        onChange={handleChange}
        className={'language-input'}
      >
        {Object.keys(lngs).map((lng, i) => (
          <MenuItem key={lng} value={lng}>
            {i == 0 ? <IconFlagUK className={'flag-icon'} /> : <IconFlagDE className={'flag-icon'} />}
            {lngs[lng].nativeName}
          </MenuItem>
        ))}
      </Select>
    </React.Fragment>
  );
};
