import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { startModifyForm } from '../../../redux/actions/form';
import { TitleBlock } from '../../../components/blocks/TitleBlock';
import { InfoBlock } from '../../../components/blocks/InfoBlock';
import { ActionBar } from '../../../components/toolbars/ActionBar';
import { ToggleOption } from '../../../components/inputs/ToggleOption';
import { ProgressBlock } from '../../../components/blocks/ProgressBlock';
import { useTranslation } from 'react-i18next';
import { FORM_TYPES, getStepData } from '../../../enums/Steps';

export const JourneyAgeForm = (props) => {
  const form = useSelector((state) => state.form[0]);
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const step = 'age';
  const { currentStep, nextStep, prevStep, currentStepIndicator, actionBarCopy } = getStepData(
    step,
    FORM_TYPES.journey
  );

  const Ages = [
    {
      value: '<20',
      name: '<20',
    },
    {
      value: '20-29',
      name: '20-29',
    },
    {
      value: '30-39',
      name: '30-39',
    },
    {
      value: '40-49',
      name: '40-49',
    },
    {
      value: '50-59',
      name: '50-59',
    },
    {
      value: '60-69',
      name: '60-69',
    },
    {
      value: '70-79',
      name: '70-79',
    },
    {
      value: '80-89',
      name: '80-89',
    },
    {
      value: '90+',
      name: '90+',
    },
  ];

  const Genders = [
    {
      value: 'male',
      name: t('FORM_AGE.GENDER_OPTION_ONE'),
      icon: 'male-icon',
    },
    {
      value: 'female',
      name: t('FORM_AGE.GENDER_OPTION_TWO'),
      icon: 'female-icon',
    },
  ];

  const handleAgeSelect = (age) => {
    dispatch(startModifyForm({ ...form, patientAge: age }));
  };

  const handleGenderSelect = (gender) => {
    dispatch(startModifyForm({ ...form, patientGender: gender }));
  };

  const [validation, setValidation] = useState(false);
  const validate = () => {
    setValidation(true);
  };

  return (
    <React.Fragment>
      <ProgressBlock value1={currentStepIndicator} value2={0} />
      <div className="inner-form-container">
        <TitleBlock copy={t('FORM_AGE.AGE_TITLE')} />
        <ToggleOption
          options={Ages}
          handleSelect={handleAgeSelect}
          selected={form.patientAge}
          validation={validation}
        />

        <TitleBlock copy={t('FORM_AGE.GENDER_TITLE')}></TitleBlock>
        <ToggleOption
          options={Genders}
          handleSelect={handleGenderSelect}
          selected={form.patientGender}
          validation={validation}
        />
      </div>
      <ActionBar
        prevState={prevStep}
        copy={actionBarCopy}
        handleValidation={validate}
        nextState={form.patientAge ? (form.patientGender ? nextStep : '') : ''}
      />
    </React.Fragment>
  );
};
