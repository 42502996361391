import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { startModifyForm } from '../../../redux/actions/form';
import { Typography, Box } from '@material-ui/core';
import { ActionBar } from '../../../components/toolbars/ActionBar';
import { ProgressBlock } from '../../../components/blocks/ProgressBlock';
import { SafeSevenInput } from '../../../components/inputs/SafeSevenInput';
import { useTranslation } from 'react-i18next';

export const Safe7Three = (props) => {
  const form = useSelector((state) => state.form[0]);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleSafeSevenSelect = (value) => {
    dispatch(startModifyForm({ ...form, safeSevenThree: value }));
  };

  const [validation, setValidation] = useState(false);
  const validate = () => {
    setValidation(true);
  };

  return (
    <React.Fragment>
      <ProgressBlock value1={100} value2={42} />
      <div className={'inner-form-container'}>
        <Box my={4}>
          <Typography variant={'h5'} align={'center'} className={'blue-font'}>
            <strong>{t('SAFE_SEVEN.Q_THREE')}</strong>
          </Typography>
        </Box>
        <Box my={4}>
          <div className="safe7-graphic safe7-graphic-3"></div>
        </Box>
        <SafeSevenInput selected={form.safeSevenThree} handleSelect={handleSafeSevenSelect} validation={validation} />
      </div>
      <ActionBar
        prevState={'safe-2'}
        handleValidation={validate}
        copy={'3 of 7'}
        nextState={form.safeSevenThree ? 'safe-4' : ''}
      />
    </React.Fragment>
  );
};
