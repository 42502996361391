import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export const AlertBlock = (props) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      {props.validation && props.active ? (
        <div className={'alert-box'}>
          <Typography variant={'subtitle1'} align={'center'}>
            {props.copy ? props.copy : t('PLEASE_SELECT_OPTION')}
          </Typography>
        </div>
      ) : (
        <Box my={3.109}></Box>
      )}
    </React.Fragment>
  );
};
