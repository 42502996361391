import './App.css';

import { JourneyWrapper } from './pages/wrappers/JourneyWrapper';
import { BrowserRouter as Router } from 'react-router-dom';

import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

function App() {
  const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
  if (path) {
    history.replace(path);
  }

  return (
    <Router>
      <div className="App">
        <JourneyWrapper />
      </div>
    </Router>
  );
}

export default App;
