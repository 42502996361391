import React from 'react';
import { Box, TextField } from '@material-ui/core';

export const TextInput = (props) => {
  return (
    <React.Fragment>
      <Box display={'flex'} justifyContent={'center'}>
        <TextField
          rows={`${props.isMultiline ? 6 : 1}`}
          multiline={`${props.isMultiline ? true : false}`}
          defaultValue={props.defaultValue}
          variant="outlined"
          className={'theme-input'}
          onKeyUp={(e) => props.handleKeyUp(e)}
        ></TextField>
      </Box>
    </React.Fragment>
  );
};
