import React, { useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { startModifyForm } from '../../redux/actions/form';
import { Grid, Box, Typography, Button } from '@material-ui/core';

import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Radiolist } from '../../components/inputs/Radiolist';
import { LanguageInput } from '../../components/inputs/LanguageInput';

import { useTranslation } from 'react-i18next';
import { SERVICE_TYPE_OPTIONS } from '../../enums/ServiceTypeOptions';
import { ToggleOption } from '../../components/inputs/ToggleOption';

export const StartForm = () => {
  const form = useSelector((state) => state.form[0]);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const CarerOptions = [
    {
      name: t('FORM_START.OPTION_ONE'),
      value: 'patient',
    },
    {
      name: t('FORM_START.OPTION_TWO'),
      value: 'other',
    },
  ];

  const handleIsCarerSelect = (value) => {
    dispatch(startModifyForm({ ...form, isCarer: value }));
  };

  const startSurvey = () => {
    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 100);
    dispatch(
      startModifyForm({
        ...form,
        formState: 'info',
      })
    );
  };

  const [validation, setValidation] = useState(false);
  const validate = () => {
    setValidation(true);
  };

  const [serviceTypeValidation, setServiceTypeValidation] = useState(false);
  const handleServiceTypeValidate = () => {
    setServiceTypeValidation(true);
  };

  const handleServiceTypeSelect = (serviceType) => {
    dispatch(startModifyForm({ ...form, serviceType: serviceType }));
    handleServiceTypeValidate();
  };

  const canSubmit = useMemo(() => !!form.isCarer && !!form.serviceType, [form.isCarer, form.serviceType]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <LanguageInput />
      </Grid>
      <Grid item xs={12} md={6}>
        <Box display={'flex'} justifyContent={'center'}>
          <div className="intro-graphic"></div>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box my={4}>
          <Typography variant="h4" className="blue-font">
            <strong>{t('FORM_START.TITLE')}</strong>
          </Typography>
        </Box>
        <Box my={2}>
          <Typography variant="subtitle1" className="blue-font">
            {t('FORM_START.PARA_ONE')}
            <br />
            <br />
            {t('FORM_START.PARA_TWO')}
            <br />
            <i>{t('FORM_START.PARA_THREE')}</i>
          </Typography>
        </Box>
        <Radiolist
          options={CarerOptions}
          selected={form.isCarer}
          isRows={false}
          handleSelect={handleIsCarerSelect}
          validation={validation}
        />
        <Box my={2}>
          <Typography variant="h6" className="blue-font">
            Which service have you been seen in and are reporting your experience on?
          </Typography>
          <ToggleOption
            validationIndicatorMoreRight={true}
            options={SERVICE_TYPE_OPTIONS}
            handleSelect={handleServiceTypeSelect}
            selected={form.serviceType}
            validation={serviceTypeValidation}
            twoButtonRow={true}
          />
        </Box>

        <Box my={2}>
          <Button
            endIcon={<ChevronRightIcon fontSize={'large'} />}
            className={`start-survey-btn ${canSubmit ? '' : 'inactive'}`}
            variant={'outlined'}
            onClick={canSubmit ? startSurvey : validate}
          >
            {t('FORM_START.BUTTON')}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};
