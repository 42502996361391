import React from 'react';
import { Box, Typography } from '@material-ui/core';

export const Unauthorized = () => {
  return (
    <Box mx={2}>
      <Typography align={'center'} variant={'h5'}>
        The link you have used to access the Safe-7 survey has expired and is no longer valid. If you would like a new
        link to the survey please contact us at <a href="mailto:support@nhs.co.uk">support@nhs.co.uk</a>
      </Typography>
    </Box>
  );
};
