export const DE = {
  PLEASE_SELECT_OPTION: 'Please select an option',
  DATE_SELECTED: 'Datum ausgewählt',
  NOT_KNOWN: 'Ich weiß nicht',
  BEST: 'Best',
  WORST: 'Worst',
  MORE_INFO: {
    TITLE: 'Weitere Informationen',
  },
  PROGRESS: {
    ONE: 'Ihre Reise mit uns',
    TWO: 'Die Safe-7 Fragen',
  },
  FORM_START: {
    TITLE: 'Wir schätzen Ihre Meinung',
    PARA_ONE:
      'Diese anonyme Umfrage wird uns helfen, die Pflegeerfahrungen unserer Patienten und ihrer Familien/Betreuer zu verstehen. Es wurde von unseren Patienten für unsere Patienten entwickelt. Es gibt Echtzeit-Feedback zu den von uns angebotenen Dienstleistungen und stellt sicher, dass wir ständig versuchen, Verbesserungsmöglichkeiten zu finden',
    PARA_TWO:
      'Vielen Dank, dass Sie sich die Zeit genommen haben, diese anonyme Umfrage auszufüllen. Ihre Kommentare helfen uns dabei, dass sich jeder Patient bei uns sicher fühlt. ',
    PARA_THREE: 'Das Team für Lungenkrebs und Thoraxchirurgie im Wythenshawe Hospital',
    BUTTON: 'Umfrage starten',
    OPTION_ONE: 'Ich bin der Patient',
    OPTION_TWO: 'Ich bin das Familienmitglied / die Pflegeperson des Patienten',
  },
  FORM_INFO: {
    TITLE: 'Sie und Ihre Reise mit uns',
    SUBHEADING:
      'Wenn wir die Pflegeerfahrung unserer Patienten untersuchen, ist es wichtig zu wissen, ob alle Patienten unabhängig von Alter, Geschlecht, ethnischer Zugehörigkeit, finanziellem Status, sexueller Orientierung usw. die gleiche Pflegeerfahrung erhalten',
    BULLET_ONE:
      'Wir möchten Ihnen daher einige Fragen zu Ihrer Person stellen, damit wir die Pflegeerfahrung unserer Patienten in all diesen Bereichen verstehen können, die jeden Patienten einzigartig machen',
    BULLET_TWO:
      'Diese Fragen sind anonym und es werden keine Daten gespeichert, die Sie individuell identifizieren könnten.',
    CONCLUSION:
      'Vielen Dank, dass Sie sich die Zeit genommen haben, an dieser Umfrage teilzunehmen und uns etwas mehr über Sie und Ihre Reise mit uns zu erzählen.',
  },
  FORM_DATES: {
    INFO_BOX: 'das ist ein Beispieltext',
    DATE_RAISED_TITLE: 'das ist ein Beispieltext?',
    DATE_RAISED_MORE_INFO_CONTENT: 'das ist ein Beispieltext',
    DATE_EXPLAINED_TITLE: 'das ist ein Beispieltext?',
    DATE_EXPLAINED_MORE_INFO_CONTENT: 'das ist ein Beispieltext',
  },
  DATE_INPUT: {
    DATE_SELECTED: 'Beispieltext',
    DO_NOT_KNOW: 'Beispieltext',
  },
  FORM_AGE: {
    AGE_TITLE: 'How old are you at the time of completing this survey?',
    GENDER_TITLE: 'Geschlecht',
    GENDER_MORE_INFO: 'Lorem Ipsum',
    GENDER_OPTION_ONE: 'Männlich',
    GENDER_OPTION_TWO: 'Weiblich',
  },
  FORM_POSTCODE: {
    LIVE_ALONE_TITLE: 'Leben Sie allein?',
    LIVE_ALONE_MORE_INFO: 'Lorem ipsum, but in German',
    LIVE_ALONE_OPTION_ONE: 'Ja',
    LIVE_ALONE_OPTION_TWO: 'Nein',
    LIVE_ALONE_OPTION_THREE: 'Prefer Not To Say',
    POSTCODE_TITLE: 'Was ist Ihre Postleitzahl?',
    POSTCODE_MORE_INFO: 'Lorem ipsum, but in German',
  },
  FORM_TRANSPORT: {
    TRANSPORT_TITLE: 'What is the most common form of transport you use to travel to hospital appointments and tests?',
    TRANSPORT_OPTION_ONE: 'Drive my own vehicle',
    TRANSPORT_OPTION_TWO: 'Public Transport (Bus, Train, Etc)',
    TRANSPORT_OPTION_THREE: 'Taxi',
    TRANSPORT_OPTION_FOUR: 'Family / Friend Drives Me',
    TRANSPORT_OPTION_FIVE: 'Hospital Transport Service',
    TRANSPORT_OPTION_SIX: 'Others',
    TRANSPORT_OPTION_OTHER: 'Please specify',
    HOSPITAL_COUNT_TITLE: 'How many different hospitals did you have to travel to complete all your tests?',
    HOSPITAL_COUNT_MORE_INFO: 'Lorem ipsum',
    HOSPITAL_OPTION_NOT_SURE: "I'm Not Sure",
  },
  FORM_TESTS: {
    TEST_TITLE: 'Please tick all the different tests you have had under our care',
    TEST_OPTION_ONE: 'CT scan of the lungs',
    TEST_OPTION_TWO: 'PET scan',
    TEST_OPTION_THREE: 'Brain scan',
    TEST_OPTION_FOUR: 'Bronchoscopy',
    TEST_OPTION_FIVE: 'EBUS',
    TEST_OPTION_SIX: 'Lung biopsy',
    TEST_OPTION_SEVEN: 'CT scan or MR scan of a different part of the body',
    TEST_OPTION_EIGHT: 'A different kind of biopsy',
    TEST_OPTION_NINE: 'Breathing tests',
    TEST_OPTION_TEN: 'Heart scan',
    TEST_OPTION_ELEVEN: 'Exercise test',
    TEST_OPTION_TWELVE: "I'm not sure",
    TEST_OPTION_THIRTEEN: 'Others',
    TEST_OPTION_OTHER: 'Please specify',
  },
  FORM_ETHNICITY: {
    ETHNICITY_QUESTION: 'Which of these best describes your ethnic group?',
    ETHNICITY_OPTION_AFRICAN: 'African',
    ETHNICITY_OPTION_ARAB: 'Arab',
    ETHNICITY_OPTION_ASIAN_BANGLADESHI: 'Asian - Bangladeshi',
    ETHNICITY_OPTION_ASIAN_CHINESE: 'Asian - Chinese',
    ETHNICITY_OPTION_ASIAN_INDIAN: 'Asian - Indian',
    ETHNICITY_OPTION_ASIAN_OTHER: 'Asian - Any other Asian background',
    ETHNICITY_OPTION_ASIAN_PAKISTANI: 'Asian - Pakistani',
    ETHNICITY_OPTION_BLACK_OTHER: 'Any other Black / African / Caribbean background',
    ETHNICITY_OPTION_CARIBBEAN: 'Caribbean',
    ETHNICITY_OPTION_MIXED_MULTIPLE_ETHNIC: 'Any other Mixed/Multiple ethnic background',
    ETHNICITY_OPTION_OTHER: 'Any other ethnic group',
    ETHNICITY_OPTION_WHITE: 'White',
    ETHNICITY_OPTION_WHITE_ASIAN: 'White and Asian',
    ETHNICITY_OPTION_WHITE_BLACK_AFRICAN: 'White and Black African',
    ETHNICITY_OPTION_WHITE_BLACK_CARIBBEAN: 'White and Black Caribbean',
  },
  FORM_GENDER: {
    GENDER_IDENTITY_TITLE: 'Which of the following describes how you think of yourself?',
    GENDER_IDENTITY_MORE_INFO: 'Lorem Ipsum',
    GENDER_IDENTITY_ANOTHER_WAY: 'In Another Way',
    GENDER_IDENTITY_MAN: 'Man (including trans men)',
    GENDER_IDENTITY_NON_BINARY: 'Non-binary',
    GENDER_IDENTITY_NOT_SAY: 'Prefer not to say',
    GENDER_IDENTITY_WOMAN: 'Woman (including trans women)',
    GENDER_AT_BIRTH_TITLE: 'Is your gender the same gender you were given at birth?',
    GENDER_AT_BIRTH_MORE_INFO: 'Lorem Ipsum',
    GENDER_BIRTH_NO: 'No',
    GENDER_BIRTH_NOT_SAY: 'Prefer not to say',
    GENDER_BIRTH_YES: 'Yes',
    GENDER_SEXUAL_ORIENTATION_TITLE: 'Which of these best describes your sexual orientation?',
    GENDER_SEXUAL_ORIENTATION_MORE_INFO: 'Lorem Ipsum',
    GENDER_SEXUAL_ORIENTATION_OPTION_ONE: 'Straight/Heterosexual',
    GENDER_SEXUAL_ORIENTATION_OPTION_TWO: 'Homosexual, lesbian, gay',
    GENDER_SEXUAL_ORIENTATION_OPTION_THREE: 'Bisexual',
    GENDER_SEXUAL_ORIENTATION_OPTION_FOUR: 'Prefer not to say',
  },
  SAFE_SEVEN: {
    TITLE: 'The Safe 7',
    PARAGRAPH:
      "You will now be asked to rate your experience of care in the 'pillars of experience of care' defined by our patient representatives that have designed this survey. Please rate your experience of care in each of these areas. At the end you will be able to write specific feedback (good or bad) and please do provide as much feedback as possible. Thank you for taking the time to complete this survey.",
    Q_ONE: '1. How satisfied were you with the speed of the service?',
    Q_TWO: '2. How clear was the information you were provided with by our team?',
    Q_THREE: '3. How easily were you able to access the team if you needed them?',
    Q_FOUR: '4. How kind were the team that cared for you?',
    Q_FIVE: '5. How would you rate your level of trust and confidence in the team that cared for you?',
    Q_SIX: '6. How would you rate how well we listened to you and your worries/concerns?',
    Q_SEVEN: 'Overall how safe did you feel under our care?',
  },
  FORM_EXPERIENCE: {
    TITLE: 'Your Experience',
    GOOD: 'Please let us know if there was anything that was especially good about your experience',
    BAD: 'Please let us know if there was anything that was especially bad about your experience',
  },
  CONFIRMATION: {
    THANK_YOU: 'Thank you for completing the survey!',
    CONTENT:
      'We value your opinion and really appreciated that you took your time to answer those questions. We would also like to make clear that we will not store or share your data with any third-party company.',
  },
  ERROR: {
    MESSAGE: 'Sorry, there was an error when submitting the survey!',
    CONTENT: 'Please press the button to retry. If the issue persists, please contact us at ',
    LINK: 'support@nhs.co.uk',
  },
};

export default DE;
