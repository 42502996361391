const formReducerDefaultState = [
  {
    isCarer: null,
    serviceType: null,
    patientAge: null,
    patientGender: null,
    patientLivingArrangements: null,
    patientPostcode: null,
    patientTransportToHospital: null,
    patientTransportOther: null,
    patientEthnicity: null,
    patientGenderIdentity: null,
    patientGenderAtBirth: null,
    patientSexualOrientation: null,
    safeSevenOne: null,
    safeSevenTwo: null,
    safeSevenThree: null,
    safeSevenFour: null,
    safeSevenFive: null,
    safeSevenSix: null,
    safeSevenSeven: null,
    patientGoodExperience: null,
    patientBadExperience: null,
    formState: 'start',
    key: null,
  },
];

export const formReducer = (state = formReducerDefaultState, action) => {
  switch (action.type) {
    case 'MODIFY_FORM':
      return [action.form];
    default:
      return state;
  }
};
