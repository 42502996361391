import React from 'react';
import { Box, Typography, LinearProgress } from '@material-ui/core';

export const ProgressIndicator = (props) => {
  return (
    <React.Fragment>
      <div
        className={`indicator-container ${
          props.value === 0 ? 'inactive' : props.value === 100 ? 'inactive' : 'active'
        }`}
      >
        <Box mx={4} mb={2}>
          <Typography variant={'subtitle1'} align={'center'}>
            <span
              className={`progress-indicator-number-icon ${
                props.value === 0 ? 'inactive' : props.value === 100 ? 'inactive' : 'active'
              }`}
            >
              {props.number}
            </span>{' '}
            {props.label}
          </Typography>
        </Box>
        <LinearProgress value={props.value} variant={'buffer'} valueBuffer={100} />
      </div>
    </React.Fragment>
  );
};

export default ProgressIndicator;
