import React from 'react';
import { FormControlLabel, Radio, RadioGroup, Grid } from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { AlertBlock } from '../blocks/AlertBlock';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Icon from '@material-ui/core/Icon';

export const Radiolist = (props) => {
  const handleChange = (e) => {
    props.handleSelect(e.target.value);
  };

  return (
    <div className={'radiolist form-input-container'}>
      <AlertBlock active={!props.selected} validation={props.validation} />
      {props.selected && (
        <div className={'complete-indicator-container'}>
          <CheckCircleIcon />
        </div>
      )}
      <RadioGroup aria-label="radiolist" name="radiolist" defaultValue={props.selected} onChange={handleChange}>
        <Grid container>
          {props.options.map((item, index) => {
            return (
              <React.Fragment>
                <Grid item md={props.isRows ? 6 : 12} xs={12} className="blue-font">
                  <FormControlLabel
                    key={index}
                    control={
                      <Radio
                        value={item.value}
                        icon={
                          <Icon fontSize="large">
                            <div class="custom-unchecked-icon-graphic"></div>
                          </Icon>
                        }
                        checkedIcon={
                          <Icon fontSize="large">
                            <div class="custom-checked-icon-graphic"></div>
                          </Icon>
                        }
                      />
                    }
                    label={item.name}
                  />
                </Grid>
              </React.Fragment>
            );
          })}
        </Grid>
      </RadioGroup>
    </div>
  );
};
