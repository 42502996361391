import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { startModifyForm } from '../../../redux/actions/form';
import { Typography, Box } from '@material-ui/core';
import { TextInput } from '../../../components/inputs/TextInput';
import { ActionBar } from '../../../components/toolbars/ActionBar';
import { useTranslation } from 'react-i18next';

export const Safe7Final = (props) => {
  const form = useSelector((state) => state.form[0]);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleGoodExperienceInput = (good) => {
    dispatch(startModifyForm({ ...form, patientGoodExperience: good.target.value }));
  };

  const handleBadExperienceInput = (bad) => {
    dispatch(startModifyForm({ ...form, patientBadExperience: bad.target.value }));
  };

  return (
    <React.Fragment>
      <Box my={4}>
        <Typography variant={'h2'} align={'center'} className={'blue-font'}>
          {t('FORM_EXPERIENCE.TITLE')}
        </Typography>
      </Box>
      <Box my={2}>
        <Typography variant={'h6'} align={'center'} className={'blue-font'}>
          <strong>{t('FORM_EXPERIENCE.GOOD')}</strong>
        </Typography>
      </Box>
      <TextInput isMultiline={true} handleKeyUp={handleGoodExperienceInput} defaultValue={form.patientGoodExperience} />
      <Box my={2}>
        <Typography variant={'h6'} align={'center'} className={'blue-font'}>
          <strong>{t('FORM_EXPERIENCE.BAD')}</strong>
        </Typography>
      </Box>
      <TextInput isMultiline={true} handleKeyUp={handleBadExperienceInput} defaultValue={form.patientBadExperience} />
      <ActionBar prevState={'safe-7'} nextState={'thank-you'} />
    </React.Fragment>
  );
};
