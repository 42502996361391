import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { startModifyForm } from '../../../redux/actions/form';
import { TitleBlock } from '../../../components/blocks/TitleBlock';
import { ProgressBlock } from '../../../components/blocks/ProgressBlock';
import { ToggleOption } from '../../../components/inputs/ToggleOption';
import { TextInput } from '../../../components/inputs/TextInput';
import { ActionBar } from '../../../components/toolbars/ActionBar';
import { Typography } from '@material-ui/core';
import { Transport } from '../../../enums/Transport';
import { useTranslation } from 'react-i18next';
import { FORM_TYPES, getStepData } from '../../../enums/Steps';

export const JourneyTravelForm = (props) => {
  const form = useSelector((state) => state.form[0]);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const step = 'travel';
  const { nextStep, prevStep, currentStepIndicator, actionBarCopy } = getStepData(step, FORM_TYPES.journey);

  const [otherTravelSelected, setOtherTravelSelected] = useState(false);

  useEffect(() => {
    if (form.patientTransportToHospital !== null) {
      if (form.patientTransportToHospital.includes('others')) {
        setOtherTravelSelected(true);
      }
    }
  }, [form.formState]);

  const handleTravelOtherInput = (otherTravel) => {
    dispatch(startModifyForm({ ...form, patientTransportOther: otherTravel.target.value }));
  };

  const handleTravelSelect = (travel) => {
    dispatch(startModifyForm({ ...form, patientTransportToHospital: travel }));
    travel.includes('others') ? setOtherTravelSelected(true) : setOtherTravelSelected(false);
  };

  const handleHospitalCountSelect = (hospitalCount) => {
    dispatch(startModifyForm({ ...form, patientHospitalCount: hospitalCount }));
  };

  const [validation, setValidation] = useState(false);
  const validate = () => {
    setValidation(true);
  };

  return (
    <React.Fragment>
      <ProgressBlock value1={currentStepIndicator} value2={0} />
      <div className="inner-form-container">
        <TitleBlock copy={t('FORM_TRANSPORT.TRANSPORT_TITLE')}></TitleBlock>
        <ToggleOption
          options={Transport}
          handleSelect={handleTravelSelect}
          selected={form.patientTransportToHospital}
          isMultiple={true}
          validation={validation}
        />
        {otherTravelSelected && (
          <React.Fragment>
            <Typography align={'center'} className={'blue-font'} variant={'h6'}>
              <strong>Please specify</strong>
            </Typography>
            <TextInput handleKeyUp={handleTravelOtherInput} defaultValue={form.patientTransportOther} />
          </React.Fragment>
        )}
      </div>
      <ActionBar
        prevState={prevStep}
        handleValidation={validate}
        copy={actionBarCopy}
        nextState={form.patientTransportToHospital ? nextStep : ''}
      />
    </React.Fragment>
  );
};
