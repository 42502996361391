import React from 'react';
import { Typography, Box, FormControlLabel, Radio, RadioGroup, Grid } from '@material-ui/core';
import { AlertBlock } from '../blocks/AlertBlock';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useTranslation } from 'react-i18next';

export const SafeSevenInput = (props) => {
  const safe7 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const { t } = useTranslation();
  const handleChange = (e) => {
    props.handleSelect(e.target.value);
  };

  return (
    <React.Fragment>
      <AlertBlock active={!props.selected} validation={props.validation} />
      <RadioGroup aria-label="safe-7" name="safe-7" defaultValue={props.selected} onChange={handleChange}>
        <Grid container style={{ display: 'flex', justifyContent: 'center' }}>
          {safe7.map((item, index) => {
            return (
              <React.Fragment>
                <div className={'safe7-radio-container'}>
                  <FormControlLabel
                    key={index}
                    labelPlacement={'bottom'}
                    control={
                      <Radio
                        value={item}
                        checked={parseInt(props.selected) === index + 1}
                        icon={
                          <FiberManualRecordIcon
                            className={'safe-seven-radio safe-seven-radio-unchecked'}
                            fontSize="large"
                          />
                        }
                        checkedIcon={
                          <CheckCircleIcon className={'safe-seven-radio safe-seven-radio-checked'} fontSize="large" />
                        }
                      />
                    }
                    label={item}
                  />
                  {index === 7 && props.demo && <div className={'hand-icon-graphic'}></div>}
                  {index === 0 && (
                    <Box mt={1} display={'flex'} justifyContent={'center'}>
                      <Typography align={'center'}>{t('WORST')}&nbsp;&nbsp;&nbsp;&nbsp;</Typography>
                    </Box>
                  )}
                  {index === 9 && (
                    <Box mt={1} display={'flex'} justifyContent={'center'}>
                      <Typography align={'center'}>{t('BEST')}&nbsp;&nbsp;&nbsp;&nbsp;</Typography>
                    </Box>
                  )}
                </div>
              </React.Fragment>
            );
          })}
        </Grid>
      </RadioGroup>
    </React.Fragment>
  );
};
