export const SERVICE_TYPES = {
  rapid_hub_morning: 'RAPID hub (Morning visit with same day CT scan & clinic appointment)',
  post_mdt_clinic: 'Post-MDT clinic (Test results clinic after ‘MDT’ meeting)',
  survivorship_clinic: 'Survivorship clinic (Follow-up clinic after treatment)',
  pleural_clinic: 'Pleural clinic',
  pleural_procedure_list: 'Pleural procedure list',
  rapid_hub_other: 'RAPID hub: other',
  bronchoscopy_procedure: 'Bronchoscopy procedure',
  lung_biopsy_procedure: 'Lung biopsy procedure',
  oncology_clinic_first: 'Oncology clinic: first appointment as a new patient',
  oncology_clinic_follow_up: 'Oncology clinic: follow-up appointment',
  cecelia_unit: 'Cecelia Unit – treatment visit',
  lung_surgery_clinic_first: 'Lung Surgery: first appointment as a new patient',
  lung_surgery_clinic_follow_up: 'Lung Surgery: follow-up appointment',
  one_stop_lung_cancer: 'One-stop lung cancer clinic',
};

export const SERVICE_TYPE_OPTIONS = [
  { name: 'RAPID hub (Morning visit with same day CT scan & clinic appointment)', value: 'rapid_hub_morning' },
  { name: 'Post-MDT clinic (Test results clinic after ‘MDT’ meeting)', value: 'post_mdt_clinic' },
  { name: 'Survivorship clinic (Follow-up clinic after treatment)', value: 'survivorship_clinic' },
  { name: 'Pleural clinic', value: 'pleural_clinic' },
  { name: 'Pleural procedure list', value: 'pleural_procedure_list' },
  { name: 'RAPID hub: other', value: 'rapid_hub_other' },
  { name: 'Bronchoscopy procedure', value: 'bronchoscopy_procedure' },
  { name: 'Lung biopsy procedure', value: 'lung_biopsy_procedure' },
  { name: 'Oncology clinic: first appointment as a new patient', value: 'oncology_clinic_first' },
  { name: 'Oncology clinic: follow-up appointment', value: 'oncology_clinic_follow_up' },
  { name: 'Cecelia Unit – treatment visit', value: 'cecelia_unit' },
  { name: 'Lung Surgery: first appointment as a new patient', value: 'lung_surgery_clinic_first' },
  { name: 'Lung Surgery: follow-up appointment', value: 'lung_surgery_clinic_follow_up' },
  { name: 'One-stop lung cancer clinic', value: 'one_stop_lung_cancer' },
];
